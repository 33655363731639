import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouteLoaderData } from "@remix-run/react";
import { Fragment } from "react";
import { loadTranslator } from "~/utils/loadTranslator";

interface SocialLinkProps {
  method: "twitter" | "google";
  type: "signin" | "signup";
  loading?: boolean;
  onClick: () => void;
}

export const SocialLinkIcons = {
  twitter: (
    <svg viewBox="0 0 24 24" width="100%" height="100%" fill="currentColor">
      <g>
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
      </g>
    </svg>
  ),
  google: <img src={require("~/images/google.svg")} className="w-6 h-6" alt="" />
};

export default function SocialLink({ method, type, loading, onClick }: SocialLinkProps) {
  const t = loadTranslator(useRouteLoaderData("root").translations);

  const title = {
    twitter: type === "signin" ? t("sign-in-with-x") : t("signup-with-x"),
    google: type === "signin" ? t("sign-in-with-google") : t("signup-with-google")
  };

  return (
    <button
      type="button"
      title={title[method]}
      aria-label={title[method]}
      className="flex justify-center items-center px-5 py-2 gap-x-2.5 min-w-[190px] min-h-[42px] rounded-full bg-pri dark:bg-pri-d border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      ) : (
        <Fragment>
          <span className="w-6 h-6">{SocialLinkIcons[method]}</span>

          <span className="text-sm font-medium">{title[method]}</span>
        </Fragment>
      )}
    </button>
  );
}
